import { shuffle } from "lodash";
import { graphql } from "gatsby";
import React, { Component, useState } from "react";
import Helmet from "react-helmet";
import styled, { css } from "styled-components";
import { Button, H2, H3, H4, P, Small, theme } from "@upsolve/ui";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import ImageInContext from "../components/Media/ImageInContext";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import CSSInjector from "../components/Layout/CSSInjector";
import WaveSVG from "../../static/images/illustrations/wave.svg";
import TeamPictureWall from "../components/team/TeamPictureWall";
import { CTATarget } from "../components/CTA/getCTAData";

const contentGroup = CONTENT_GROUPS.LANDING_PAGE;
const templateProps = {
  pageComponentName: "Team",
  pageComponentVersion: 0,
};
const trackingPageContext = { contentGroup, ...templateProps };

const teamMemberData = [
  {
    name: "Ben Jackson",
    imageKey: "benJackson",
    title: "COO",
    email: "ben@upsolve.org",
  },
  {
    name: "Dinah Ilagan",
    imageKey: "dinah",
    title: "Executive Assistant to Jonathan Petts",
    email: "dinah@upsolve.org",
  },
  {
    name: "Fernando Urbina",
    imageKey: "fernandoUrbina",
    title: "Chief of Staff",
    email: "fernando@upsolve.org",
  },
  {
    name: "Jacqueline Sadlo",
    imageKey: "jacquelineSadlo",
    title: "Paralegal, User Advocate",
    email: "jacky@upsolve.org",
  },
  {
    name: "Jeff Zhou",
    imageKey: "jeff",
    title: "CTO",
    email: "jeff@upsolve.org",
  },
  {
    name: "Jonathan Petts",
    imageKey: "jonathanPetts",
    title: "CEO",
    email: "jonathan@upsolve.org",
  },
  {
    name: "Mae Koppes",
    imageKey: "maeKoppes",
    title: "Content Manager",
    email: "mae@upsolve.org",
  },
  {
    name: "Zev Izenberg",
    imageKey: "zev",
    title: "Software Engineer",
    email: "zev@upsolve.org",
  },
];

const TeamMemberProfile = ({ teamMember, teamMemberImage }) => {
  const [visibleBio, setVisibleBio] = useState(false);
  return (
    <div className="team-member">
      <div className={`team-member__picture ${teamMemberImage ? "has-picture" : ""}`}>
        {teamMemberImage?.image?.fluid || teamMemberImage?.childImageSharp?.fluid ? (
          <ImageInContext
            alt={teamMember.name}
            fluid={teamMemberImage?.image?.fluid || teamMemberImage?.childImageSharp?.fluid}
          />
        ) : (
          <img alt={teamMember.name} src="/images/blob-attorneyHeadShot.png" />
        )}
      </div>
      {teamMember.url ? (
        <h3>
          <a href={teamMember.url}>{teamMember.name}</a>
        </h3>
      ) : (
        <h3>{teamMember.name}</h3>
      )}
      <h5>{teamMember.title}</h5>
      <div className={`team-member__bio ${visibleBio && "visible"}`}>{teamMember.bio}</div>
      {teamMember.bio && (
        <Button
          size="xs"
          buttonType="white"
          className="team-member__bio-toggle"
          onClick={() => setVisibleBio(!visibleBio)}
        >
          {!visibleBio ? "Read Bio" : "Close Bio -"}
        </Button>
      )}
    </div>
  );
};

class Team extends Component {
  state = {
    teamMembers: teamMemberData,
  };

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    const { data } = this.props;
    const { teamMembers } = this.state;
    const teamAuthors = (this.props.data.allContentfulAuthor.nodes || [])
      .filter((author) => {
        // Filter Upsolve accounts
        if (/upsolve/gi.test(author.name)) return false;
        // Filter past team members/writers or current team members that are also authors
        const pastTeamMembers = [
          "Attorney Andrea Wimmer",
          "Attorney Jonathan Petts",
          "Kristin Turner, Harvard Law Grad",
          "Rohan Pavuluri",
        ];
        if (pastTeamMembers.includes(author.name)) return false;
        return true;
      })
      .map((author) => ({
        ...author,
        bio: author?.biography?.biography,
        title: "Education & Content",
        name: author.name,
      }));

    return (
      <TopLevelLayout showPersistentCta={false}>
        <div className="index-container">
          <Helmet title="Upsolve Team" />
          <SEO
            title="Team | Upsolve"
            description="Upsolve is a team of lawyers, engineers, and judges who aim to transform legal services for low-income Americans."
            image="https://upsolve.org/images/meta-probono.png"
            url="https://upsolve.org/team/"
            jsonLD={[...teamMemberData, ...teamAuthors].map((teamMember) => ({
              "@context": "http://schema.org",
              "@type": "Person",
              description: teamMember.bio,
              email: teamMember.email,
              jobTitle: teamMember.title,
              knowsAbout: "Bankruptcy, Chapter 7, Debt",
              name: teamMember.name,
            }))}
          />
          <CSSInjector
            injectCSS={`body { background: linear-gradient(0deg, ${theme.colors.brand[100]}, ${theme.colors.brand[300]}); }`}
          />
          <Header trackingPageContext={trackingPageContext} ctaTarget={CTATarget.DEBT_TRIAGE} />

          <StyledTeamPage>
            <div className="team__header">
              <Small>Our Mission</Small>
              <H2 as="h1">Everyone Deserves Access to a Fresh Start</H2>
              <H4 as="h2">
                We're leading a movement to fight for a legal & financial system we can all access, combining direct
                services and advocacy. Using education, technology, and community, Upsolve empowers low-income and
                working-class families to access their legal rights and improve their financial situation. Partnering
                with our large base of Upsolve users, we also fight to fundamentally change a system stacked against us.
              </H4>
            </div>

            <div className="team__transition-waves">
              <WaveSVG />
              <WaveSVG />
            </div>

            <div className="team__list">
              <TeamPictureWall />
              <H2>Your Upsolve Team</H2>
              <P>
                Besides being lawyers, engineers, academics, and judges we are most importantly people that believe
                nobody should be too broke to get back on their feet.
              </P>
              <div className="team__list__team-members">
                {teamMembers.map((teamMember) => (
                  <TeamMemberProfile
                    key={teamMember.name}
                    teamMember={teamMember}
                    teamMemberImage={data[teamMember.imageKey]}
                  />
                ))}
                {/* HIDING TEAM AUTHORS to focus in on core team members */}
                {/* {teamAuthors.map((teamAuthor) => (
                  <TeamMemberProfile
                    key={teamAuthor.name}
                    teamMember={{ name: teamAuthor.name, title: "Education & Content", bio: teamAuthor?.bio }}
                    teamMemberImage={teamAuthor.image}
                  />
                ))} */}
              </div>
              {/* <P className="team__list__alumni">
                Special thanks to those who have joined us over the years as fellows: <b>Kristin Turner (Harvard Law Access to Justice Fellow)</b>; and interns: <b>Mary Gao</b>, <b>Johnny Kim</b>, <b>Anika McHayle</b>, <b>Ella Michaels</b>
              </P> */}
            </div>

            <div className="team__supporters">
              <H3 as="h2">Philanthropic & Inkind Supporters</H3>
              <P>
                We couldn't have made it this far without the great organizations below who believe in us. We're lucky
                to be supported by top firms, foundations, and institutions across the law, philanthropy, government,
                education, and technology.
              </P>
              <div>
                <div>
                  <img alt="Legal Services Corporation" src={require("../../static/images/lsc.png")} />
                </div>
                <div>
                  <img alt="Robinhood" src={require("../../static/images/robinhood.png")} />
                </div>
                <div>
                  <img alt="Schmidt Futures" src={require("../../static/images/schmidt-futures.png")} />
                </div>
                <div>
                  <img alt="Black Rock" src={require("../../static/images/blackrock.png")} />
                </div>
                <div>
                  <img alt="Susan Crown Exchange" src={require("../../static/images/susan-crown-exchange.png")} />
                </div>
                <div>
                  <img alt="Breyer Labs" src={require("../../static/images/breyer-labs.png")} />
                </div>
                <div>
                  <img
                    alt="Lawyers Trust Fund of Illinois"
                    src={require("../../static/images/lawyers-trust-illinois.png")}
                  />
                </div>

                <div>
                  <img alt="Blue Ridge Labs" src={require("../../static/images/blue-ridge-labs.png")} />
                </div>
                <div>
                  <a href="https://ffwd.org/" target="_blank" rel="noreferer noopener">
                    <img alt="Fast Forward" src={require("../../static/images/fast-forward.png")} />
                  </a>
                </div>
                <div>
                  <a href="https://www.ycombinator.com/companies/12095" target="_blank" rel="noreferer noopener">
                    <img alt="Y-Combinator" src={require("../../static/images/y-combinator.jpg")} />
                  </a>
                </div>

                <div>
                  <img alt="Yale University" src={require("../../static/images/yale.png")} />
                </div>
                <div>
                  <img alt="Princeton University" src={require("../../static/images/princeton.png")} />
                </div>
                <div>
                  <img
                    alt="Graphic Advocacy Project"
                    src={require("../../static/images/graphic-advocacy-project.png")}
                  />
                </div>
                <div>
                  <img alt="NextChapter" src={require("../../static/images/next-chapter.png")} />
                </div>
                <div>
                  <a href="https://segment.com/industry/startups/" target="_blank" rel="noreferer noopener">
                    <img alt="Segment" src={require("../../static/images/segment.png")} />
                  </a>
                </div>
                <div>
                  <a href="https://contentful.com" target="_blank" rel="noreferer noopener">
                    <img alt="Contentful" src={require("../../static/images/contentful.png")} />
                  </a>
                </div>
                <div>
                  <img alt="New Media Ventures" src={require("../../static/images/nmv.jpg")} />
                </div>
                <div>
                  <img alt="Silicon Valley Community Foundation" src={require("../../static/images/svcf.png")} />
                </div>
                <div>
                  <img alt="Khosla Ventures" src={require("../../static/images/khosla-ventures.png")} />
                </div>
              </div>
              <P className="probono">
                <b>Proud to be represented pro bono by:</b>
              </P>
              <div className="probono">
                <div>
                  <img alt="Weil" src={require("../../static/images/weil.jpg")} />
                </div>
                <div>
                  <img
                    alt="The Morrison Foerster Foundation"
                    src={require("../../static/images/morrisonfoerster.jpg")}
                  />
                </div>
              </div>
            </div>

            <div className="team__directors">
              <H3 as="h2">Board of Directors</H3>
              <div>
                <div>
                  <h3>Mark Hansen</h3>
                  <h5>Cofounder, Upsolve</h5>
                </div>
                <div>
                  <h3>Alisa Pratt</h3>
                  <h5>Bronx Community Leader</h5>
                </div>
                <div>
                  <h3>Scott Kelly</h3>
                  <h5>President, AfterPattern</h5>
                  <h5>Former ACLU Lawyer</h5>
                </div>
                <div>
                  <h3>Rohan Pavuluri</h3>
                  <h5>Board Chair</h5>
                </div>
                <div>
                  <h3>Milton Syed</h3>
                  <h5>Investor</h5>
                </div>
                <div>
                  <h3>Steve Lee</h3>
                  <h5>Former Robin Hood Foundation Managing Director, Income Security</h5>
                </div>
                <div>
                  <h3>Alex Mooradian</h3>
                  <h5>Consumer Debt Expert</h5>
                </div>
              </div>
            </div>

            <div className="team__advisors">
              <H3 as="h2">Advisory Board</H3>
              <div>
                <div>
                  <h3>Hon. Judith Fitzgerald</h3>
                  <h5>Bankruptcy Judge (1987-2013)</h5>
                </div>
                <div>
                  <h3>Professor Nick Sinai</h3>
                  <h5>U.S. Deputy CTO (2013-2014)</h5>
                </div>
                <div>
                  <h3>Professor Jim Greiner</h3>
                  <h5>Law Professor</h5>
                </div>
                <div>
                  <h3>Prof. Lois Lupica</h3>
                  <h5>University of Maine Law School</h5>
                </div>
                <div>
                  <h3>Warren Agin</h3>
                  <h5>Chapter 7 Trustee</h5>
                </div>
                <div>
                  <h3>Jan Baker</h3>
                  <h5>Retired Partner, Latham & Watkins LLP</h5>
                </div>
                <div>
                  <h3>Ron Peterson</h3>
                  <h5>
                    President, National Association
                    <br />
                    of Bankruptcy Trustees
                  </h5>
                </div>
                <div>
                  <h3>Edward J. Walters</h3>
                  <h5>CEO of Fastcase</h5>
                </div>
                <div>
                  <h3>Neil Devani</h3>
                  <h5>Early Stage Investor</h5>
                </div>
                <div>
                  <h3>Holly Allen</h3>
                  <h5>Senior Engineering Manager, Slack</h5>
                </div>
                <div>
                  <h3>Hon. Robert Gordon</h3>
                  <h5>Bankruptcy Judge (2006-2020)</h5>
                </div>
                <div>
                  <h3>Austin Smith</h3>
                  <h5>Partner, Smith Law Group LLP</h5>
                </div>
                <div>
                  <h3>Derison Puntier</h3>
                  <h5>Credit Building Leader</h5>
                </div>
                <div>
                  <h3>Ezra Berger CFP</h3>
                  <h5>Consumer Debt and Credit Expert</h5>
                </div>
                <div>
                  <h3>Miguel Willis</h3>
                  <h5>Innovator in Residence at Penn Law</h5>
                </div>
              </div>
            </div>
          </StyledTeamPage>
          <Footer bio={false} />
        </div>
      </TopLevelLayout>
    );
  }
}

const StyledTeamPage = styled.main`
  div.team__header {
    height: 440px;
    padding: 5em 1em 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1,
    h2,
    h4,
    p,
    small {
      color: white;
      max-width: 960px;
      line-height: 150%;
    }
    h1 {
      margin-bottom: 0.25em;
    }
    small {
      opacity: 0.5;
    }
    h2,
    h4 {
      opacity: 0.8;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      min-height: 80vh;
      height: calc(100vh + 100px);
      justify-content: flex-start;
      h1 {
        font-size: 40px;
        line-height: 140%;
      }
      h2 {
        font-size: 18px;
      }
    }
  }
  div.team__transition-waves {
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: auto;
    width: 100vw;
    max-width: 100vw;
    margin: -9em 0 -3em;
    overflow: hidden;
    svg {
      height: auto;
      width: 150vw;
    }
    svg:first-of-type {
      transform: rotate(-2deg) translateX(-5%) translateY(62%);
      path {
        fill: ${(props) => props.theme.colors.brand[500]};
      }
    }
    svg:last-of-type {
      transform: rotate(2deg) translateX(-7%);
      path {
        fill: white;
      }
    }
  }
  .team__list {
    position: relative;
    z-index: 100;
    width: 100%;
    background: white;
    padding: 0;
    margin-top: -2em;
    & > h2,
    & > p {
      max-width: 800px;
      text-align: center;
    }
    & > h2 {
      margin: 0 auto 0.25em;
      color: ${(props) => props.theme.colors.brand[500]};
    }
    & > p {
      padding: 0 1em;
      margin: 0 auto 3em;
    }
    & > div.team__list__team-members {
      width: 920px;
      margin: 0 auto;
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      grid-gap: 0.5em;
    }
    .team-member {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 320px;
      max-width: 100%;
      margin: 0 0 1em;
      &:nth-child(even) {
        position: relative;
        top: 0;
      }
      &:nth-child(odd) {
        position: relative;
        top: 0;
      }
      h3 {
        margin: 0.5em 0 0;
        text-align: center;
        a,
        a:active,
        a:visited,
        a:hover {
          color: inherit;
        }
      }
      .team-member__picture {
        height: 180px;
        min-height: 180px;
        width: 180px;
        min-width: 180px;
        border-radius: 90px;
        overflow: hidden;
        &.has-picture {
          border: 5px solid ${(props) => props.theme.colors.brand[300]};
        }
        & > img {
          width: 100%;
        }
      }
      .team-member__bio {
        overflow: hidden;
        height: 0px;
        &.visible {
          height: 100%;
          padding: 0.75em;
          margin: 0.25em 0 0.75em;
          border-radius: 18px;
          border: 2px solid ${(props) => props.theme.colors.white[500]};
          font-size: 13px;
        }
      }
    }
    .team__list__alumni {
      margin: 2em auto 3em;
      width: 100%;
      font-size: 14px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      .team-member {
        width: 100%;
        &:nth-child(even),
        &:nth-child(odd) {
          top: 0;
        }
      }
      .team__list__picture-wall {
        display: none;
      }
    }
  }
  .team__directors,
  .team__advisors,
  .team__supporters {
    background: white;
    padding: 1em 0;
    & > h2 {
      text-align: center;
      margin: 0 auto 0.5em;
      color: ${(props) => props.theme.colors.brand[500]};
    }
    & > p {
      width: 100%;
      max-width: 920px;
      padding: 0 1em;
      margin: 0 auto 1em;
      text-align: center;
      color: ${(props) => props.theme.colors.gray[900]};
      &.probono {
        color: ${(props) => props.theme.colors.brand[500]};
      }
    }
    & > div {
      width: 1080px;
      margin: 0 auto;
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      grid-gap: 2em;
      place-self: center;
      div {
        margin: 0.5em auto 1em;
        h3,
        h5 {
          text-align: center;
        }
      }
      &.probono {
        grid-template-columns: repeat(2, minmax(240px, 1fr));
      }
    }
  }
  .team__supporters {
    & > div {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      align-items: center;
      margin: 2em auto;
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        img {
          max-height: 64px;
          max-width: 100%;
        }
      }
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0.25em;
        & > div {
          padding: 0 2em;
        }
      }
    }
  }
`;

export const pageQuery = graphql`
  query TeamPage {
    allContentfulAuthor(sort: { fields: [slug], order: ASC }) {
      nodes {
        ...AuthorNode
      }
    }
    benJackson: file(base: { eq: "benJackson.png" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fernandoUrbina: file(base: { eq: "fernandoUrbina.png" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jacquelineSadlo: file(base: { eq: "jacquelineSadlo.png" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jeff: file(base: { eq: "jeff.png" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jonathanPetts: file(base: { eq: "jonathanPetts.png" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    maeKoppes: file(base: { eq: "maeKoppes.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mark: file(base: { eq: "mark.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zev: file(base: { eq: "zev.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dinah: file(base: { eq: "dinah.jpeg" }) {
      childImageSharp {
        fluid(maxHeight: 240, maxWidth: 240, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Team;
